<template>
  <v-menu  v-model="dropdown"
           :left="left"
           :disabled="disabled"
           :offset-y="!child"
           :offset-x="child"
           :position-x="x" :position-y="y"
           :absolute="absolute"
           :open-on-hover="(openOnHover && !childExpanded ) || child"
           :close-on-content-click="closeOnClick"
           z-index="9999999999999999999999999"
           transition="slide-y-transition"
           :nudge-left="nudgeLeft"
  >
    <!-- ACTIVATOR -->
    <template v-slot:activator="{attrs,on}">
      <slot name="activator">
        <v-sheet  @click="activate"
                  :v-ripple="noRipple"
                  :class="`${fillHeight ? 'd-flex align-center fill-height' : ''} ${!disabled ? 'pointer' : ''}`"
                  class="pointer d-flex align-center fill-height"
                  style="cursor: pointer"
                  v-bind="attrs" v-on="on"
                  color="transparent" rounded  >
          <slot :text="selectedText" :expand="dropdown"  v-bind="attrs" v-on="on"  default="name">
            <wsIconButton v-if="button" :icon="button"  />
          </slot>
        </v-sheet>
      </slot>
    </template>
    <v-sheet
        :color="backgroundColor"
        :min-height="minHeight"
        :max-height="maxHeight"
        :min-width="minWidth"
        :max-width="maxWidth"
        :dark="dark"
        class="d-flex flex-column"
    >
      <div v-if="searchable" class="pa-3 pb-3 ">
        <ws-text-field
            v-model="search"
            :prepend-inner-icon=" !(allowNewData && items.length === 0) ? 'mdi-magnify' : '' "
            :placeholder="$t((allowNewData && items.length === 0) ? newDataText : 'Search')"
            clearable
        >  </ws-text-field>
      </div>

      <div class="flex-grow-1 overflow-y-auto">
        <slot name="append.top" :close="close"></slot>
        <v-list
            v-if="itemsFiltered.length > 0"
            :style="backgroundColor ? `background-color: ${backgroundColor}` : ''"
            class="overflow-y-auto"
            :dark="dark"
            dense
        >
          <v-list-item-group :color="wsACCENT"  :multiple="multiple" v-model="data">

            <!-- Items -->
            <ft-select
                v-for="(item,i) in itemsFiltered"
                @input="handleClose($event,item)"
                @child-input="$emit('input' , $event);dropdown=false"
                @expand="childExpanded = $event"
                :disabled="!item.children || (item.children && item.children.length === 0)"
                :items="item.children"
                :left="left"
                :leftIcons="leftIcons"
                :openOnHover="openOnHover"
                :closeOnClick="closeOnClick"
                :iconColor="iconColor"
                :colorBlack="colorBlack"
                :translate="translate"
                :backgroundColor="backgroundColor"
                :textColor="textColor"
                :textCss="textCss"
                :delayClose="delayClose"
                :disableActions="disableActions"
                child
                :key="i"
            >
              <ws-link
                  :to="item.route"
                  :href="item.url"
                  :new-window="item.openBlank || item.open_in_new_window"
                  :disabled="disableActions || (item.children)"
                  style="width: 100%"

              >
                <v-hover v-slot="{hover}">
                  <v-list-item
                      :value="item.value"
                      @click="action(item)"
                      class="transition-ease-in-out pl-3 pr-8"
                      :color="textColor || wsACCENT"
                      :style="`background-color: ${hover ? `${!dark && !textColor ? wsLIGHTCARD : `#00000055`}` : 'transparent' }`"
                  >
                    <slot name="icon" :item="item">
                      <v-list-item-icon
                          v-if="item.icon || multiple"
                          class="px-0"
                          :class="[ {'mr-3' : minWidth !== '0' },{'mx-0' : minWidth === '0' }]"
                      >
                        <v-icon v-if="!multiple" size="20"  :color="item.iconColor || iconColor || wsACCENT" >{{ item.icon }}</v-icon>
                        <v-icon v-else size="20"  :color="wsACCENT" >{{ (data || []).includes(item.value) ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                      </v-list-item-icon>
                    </slot>

                    <v-list-item-title :style="textCss || `color : ${item.textColor || textColor || wsDARKER }`" >{{item.text}} <span v-if="displayValue">({{ item.value }})</span></v-list-item-title>
                  </v-list-item>
                </v-hover>

              </ws-link>

            </ft-select>

          </v-list-item-group>
        </v-list>
        <div v-else class="px-4 py-1">
          <slot name="noItems">
          </slot>
          <div v-if="search && !itemsFiltered.length && !!items.length">
            <h5 class="wsACCENT font-weight-regular py-5 pt-2">{{   $t( noItemsFoundText ) }}</h5>
          </div>
        </div>
      </div>

      <div>
        <v-hover #default="{hover}" v-if="allowNewData && search && !itemsFiltered.length ">
          <v-sheet
              @click="addNewItem"
              :color="hover ? wsLIGHTCARD : 'transparent'"
              class="py-3 pointer"
              style="border-top: 1px solid var(--wsBACKGROUND) !important"
              v-ripple
          >
            <h5 class="wsACCENT font-weight-regular d-flex align-center px-3">
              <v-icon class="mr-3" :color="wsACCENT">mdi-plus</v-icon>
              <span>{{ $t(newDataText) }} "{{ search }}"</span>
            </h5>
          </v-sheet>
        </v-hover>

        <!-- Multiple Buttons-->
        <div class="d-flex justify-end py-3 px-3"  v-if="multiple && value && value.length > 0 && !(search && !itemsFiltered.length)" >
          <ws-button
              @click="$emit('input',[]); dropdown= false;"
              :color="wsACCENT"
              label="ClearFilters"
              outlined height="38"

              class="mr-3"
          />
          <ws-button
              @click="dropdown=false; $emit('input',data);"
              label="Choose"
              height="38"
          />
        </div>

      </div>



    </v-sheet>
  </v-menu>
</template>

<script>
export default {
  name: "ftSelect",
  props : {
    value : {
      type : [ String, Array , Number , Object ]
    },
    items : {
      type : Array,
      default() { return [] }
    },
    dark : {
      type : Boolean,
      default : false
    },
    button : {
      type : String,
      default: ''
    },
    fillHeight : {
      type : Boolean,
      default : false
    },
    text : {
      type : String
    },
    disabled : {
      type : Boolean,
      default: false
    },
    noRipple : {
      type : Boolean,
      default : false
    },
    left  : {
      type : Boolean,
      default : false
    },
    leftIcons : {
      type : Boolean,
      default : false
    },
    expanded : {
      type : Boolean,
      default : false
    },
    x : {
      type : Number,
      default: 0
    },
    y : {
      type : Number,
      default: 0
    },
    absolute : {
      type : Boolean,
      default : false
    },
    openOnHover : {
      type : Boolean,
      default : false
    },
    searchable : {
      type : Boolean,
      default : false,
    },
    closeOnClick : {
      type : Boolean,
      default : false
    },
    maxHeight : {
      type : String,
      default : '300',
    },
    minHeight : {
      type : String,
      default : '',
    },
    minWidth : {
      type : String,
      default : '200',
    },
    displayValue : {
      type : Boolean,
      default : false
    },
    iconColor : {
      type : String,
      default : '',
    },
    colorBlack : {
      type : Boolean,
      default : false
    },
    multiple : {
      type : Boolean,
      default : false
    },
    translate : {
      type : Boolean,
      default : false
    },
    backgroundColor : {
      type : String,
    },
    textColor : {
      type : String,
    },
    textCss : {
      type : String,
    },
    delayClose : {
      type : Boolean,
      default : false
    },
    disableActions : {
      type : Boolean,
      default : false
    },
    child : {
      type : Boolean,
      default : false
    },
    nudgeLeft : {
      type : String,
      default : '0'
    },
    allowNewData : {
      type : Boolean,
      default : false
    },
    newDataText  :{
      type : String,
      default : 'Add'
    },
    noItemsFoundText : {
      type : String,
      default : 'SelectNoItemsFoundText'
    },
    noItemsText : {
      type : String,
      default : 'SelectNoItemsText'
    },
    addNewFunction : {},
    maxWidth : {}
  },
  data(){
    return {
      data : null,
      dropdown : false,
      search : '',
      childExpanded : false,
      loading : false
    }
  },
  computed : {
    itemsFiltered() {
      let items = this.items
      if ( items.length === 0 ) {
        return []
      }

      if ( this.search ) {
        items = items.filter( el => el.text.toLowerCase().includes(this.search.toLowerCase()) || el.value.toLowerCase().includes(this.search.toLowerCase()) )
      }

      return items
    },
    selectedText() {
      let text = ''
      if (this.value !== null && this.value !== undefined && !this.multiple) {
        text = this.getItemText(this.value)
      }
      else if ( Array.isArray(this.value)  && this.value.length > 0) {
        this.value.forEach((item,i) => {
          text += this.getItemText(item) + (i < this.value.length-1 ? ', ' : '' )
        })
      }
      return text
    }
  },
  watch : {
    // data(val) {
    //   this.$emit('input',val)
    // },
    value : {
      handler(val) {
        if ( this.data !== this.value ) {
          this.data = val
        }
      },
      deep : true
    },
    expanded(val) {
      if ( val !== this.dropdown ) {
        this.dropdown = val
      }
    },
    dropdown(value) {
      this.$emit('expand',value)
    }
  },
  methods : {
    async addNewItem() {
      if (this.loading) {
        return
      }
      this.loading = true
      let result = await this.addNewFunction(this.search)

      this.data.push(result.value)
      this.$emit('input',this.data)

      this.loading = false
    },
    handleClose(action,item) {
      if (this.childExpanded) {
        if ( this.child) {
          this.action(item)
        }
        this.close()
      }
    },
    getItemText(value) {
      let text = ''
      let item = this.items.find(el=> el.value === value)
      if ( item ) {
        text = item.text
      }
      return text
    },
    action(item) {
      if ( item.noAction || this.disableActions ) {
        return
      }
      if (!item.children) {
        if (!this.multiple) {

          this.data = item.value;
        } else {
          if ( !Array.isArray(this.data) ) {
            this.data = []
          }
          let index = this.data.findIndex(el => el === item.value)
          if ( index === -1 ) {
            this.data.push(item.value)
          } else {
            this.data.splice(index,1)
          }
        }

        if ( !this.child) {
          this.$emit('input',this.data)
        } else {
          this.$emit('child-input',this.data);
          this.dropdown = false
        }

        if ( !this.multiple && !this.delayClose ) {
          this.dropdown = false
        }

        if ( this.delayClose ) {
          setTimeout( () => { this.dropdown = false } , 10)
        }


      }

      if (item.action) {
        item.action()
      }
    },
    activate() {
      this.dropdown = true;
    },
    close() {
      this.dropdown = false;
    }
  },
  mounted() {
    if ( this.value || this.value === false  ) {
      this.data = this.value
    } else {
      this.data = this.multiple ? [] :  'Select'
    }
    if ( this.expanded ) {
      this.dropdown = true
    }
    if ( !this.data && this.multiple ) {
      this.data = []
    }

  }
}
</script>

<style scoped>

</style>