<template>
  <ws-dialog
      v-model="value"
      :title="$t('Order') + ': ' + order.order_id"
      no-padding no-navigation
      width="900" persistent>

      <v-sheet  class="wsRoundedHalf  justify-space-between"
                :class="displayHide(['sm']) ? 'd-flex' : '' " >
        <div style="width: 100%" :class="!SM ? 'pr-6' : '' ">
          <h4>{{  $t('Products')  }}</h4>

          <v-simple-table class="mt-6" dense >
            <tbody >
            <tr v-for="(product,key) in order.products" :key="key" >
              <td class="px-4 py-2" >
                <div class="d-flex align-center">
                  <v-avatar class="mr-2"
                            size="32"  >
                    <v-img :src="product.image_small" ratio="1" />
                  </v-avatar>
                  <h5 class="font-weight-medium">{{ product.name }}</h5>
                </div>

              </td>
              <td class="text-no-wrap">
                <h5 v-if="!order.is_course"> {{  product.quantity }} шт</h5>
              </td>
              <td class="text-no-wrap">
                 <wsChip
                     :icon="!order.is_course ? 'mdi-close' : 'mdi-cash-multiple'"
                     :text="product.price + ' ' + BUSINESS_CURRENCY" />
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td align="right" colspan="3">
                <h5 class="pr-5">Вcього: {{ getTotal(order) }} {{ BUSINESS_CURRENCY }} </h5>
              </td>
            </tr>
            </tfoot>
          </v-simple-table>

        </div>
        <v-sheet
            :width="!SM ? 400 : '' "
            class="wsRoundedHalf px-2 py-6 "
            outlined>

            <div class="smallText  mx-4 d-flex justify-space-between">
              <ws-chip icon="mdi-check-circle-outline" :text="$t('Status') + ':'" />

              <v-chip small outlined :color="[0,1,2].includes(order.status) ? 'green lighten-2' : 'grey'">
                <h5>{{ getStatusText(order.status) }}</h5>
              </v-chip>
            </div>
            <div class="smallText mt-2 mx-4 d-flex justify-space-between align-center">
              <ws-chip icon="mdi-cash-multiple" :text="$t('Payment') + ':'" />

              <ft-select @input="editPayment($event)" v-model="order.payed" :items="CHOICE_PAYED">
                <v-chip small outlined :color="order.payed ? 'green lighten-2' : 'grey'">
                  <h5 >{{ getPayedText(order.payed) }}    <v-icon small>mdi-chevron-down</v-icon></h5>
                </v-chip>

              </ft-select>

            </div>
            <div v-if="!order.is_course" class="smallText mt-2 mx-4 d-flex justify-space-between">
              <ws-chip icon="mdi-car">{{  $t('Delivery')  }}:</ws-chip>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip dark small  v-bind="attrs" v-on="on" outlined
                          :color="order.delivery_status === 'recieved' ? 'teal' : 'blue-grey '" >
                    <h4> {{ getDeliveryStatusText(order.delivery_status) }} </h4>
                  </v-chip>
                </template>
                <v-list dense dark>
                  <v-list-item-group >
                    <v-list-item   @click="editOrder(order.id,{ delivery_status : 'new'});">
                      <v-list-item-title>Готується</v-list-item-title>
                    </v-list-item>
                    <v-list-item   @click="editOrder(order.id,{ delivery_status : 'sent'});">
                      <v-list-item-title>Відправлено</v-list-item-title>
                    </v-list-item>
                    <v-list-item   @click="editOrder(order.id,{ delivery_status : 'recieved'})">
                      <v-list-item-title>Доставлено</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>

            <div v-if="!order.is_course &&  order.time "
                 class="smallText mt-4 mx-4 d-flex justify-space-between">
              <b>На час:</b>  {{ formatTime(order.time) }}
            </div>

            <div v-if="order.name"
                 class="smallText mt-6 mx-4 d-flex justify-space-between">
              <ws-chip icon="mdi-account" :text="$t('HumanName') + ':'" />
              <h5 class="font-weight-light">{{ order.name }}</h5>
            </div>

            <div v-if="order.phone"
                 class="smallText mt-2 mx-4 d-flex justify-space-between">
              <ws-chip icon="mdi-phone" :text="$t('Phone') + ':'"></ws-chip>
              <h5 class="font-weight-light">{{ order.phone }}</h5>
            </div>
            <div v-if="!order.is_course" class="smallText mt-2 mx-4 d-flex justify-space-between">
              <ws-chip icon="mdi-car" :text="$t('Delivery') + ':'"></ws-chip>
              <h5 class="font-weight-light">{{ getDeliveryType(order.delivery) }}</h5>
            </div>
            <div class="smallText mt-2 mx-4 d-flex justify-space-between">
              <ws-chip icon="mdi-cash-multiple" :text="$t('Payment') + ':'" />
              <h5 class="font-weight-light">{{ paymentName(order.payment) }}</h5>
            </div>
            <div v-if="order.city_name"
                 class="smallText mt-6 mx-4 ">
              <b>Місто:</b> <br> {{ order.city_name }}
            </div>
            <div v-if="order.address"
                 class="smallText mt-2 mx-4 ">
              <b>Адреса:</b> <br>
              {{ order.address }}<br>
            </div>
            <div v-if="order.comment"
                 class="smallText mt-2 mx-4 ">
              <b>Коментар:</b> <br>
              {{ order.comment }}<br>
            </div>
            <div v-if="order.npWarehouse" class="smallText mt-2 mx-4 ">
              <b>Відділення:</b> <br>
              {{ order.npWarehouse }}<br>
            </div>
          </v-sheet>

      </v-sheet>

  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "orderDetails",
  props : ['value','order'],
  data() {
    return {
      filterTypes :
          {
            0: 'Активний',
            1: 'Виконаний',
            2: 'Cкасований',
          }
    }
  },
  watch: {
    value() {
      this.$emit('input',this.value)
    }
  },
  methods : {
    ...mapActions('orders', [ 'EDIT_BUSINESS_ORDER']),

    changeOrder(input) {
      this.$emit('changeOrder',{id:input.id,data:input.data})
    },
    editPayment(value) {
      if ( !value ) { value = 'false' }
      this.editOrder(this.order.id,{ payed           : value })
    },
    editOrder(id,data) {
      this.loading = true
      this.order.delivery_status = data.delivery_status
      this.EDIT_BUSINESS_ORDER({id:id,data:data}).then((out)=>{

        if(out.result) {

          let notifyText = 'Cтатус замовлення змінено'


            if (this.order.payed) { data.payed = data.payed !== 'false' }
            if (this.order.status === 2 ) {  notifyText = 'Замовлення скасовано !' }
            if ( this.order.status === 'accept') { data.status = 0 }
            if ( this.order.warehouse ) {  data.warehouse = out.data.warehouse   }

            if(out.data) {
              if (out.data.completed) {
                this.order.status = 1
                notifyText = 'Замовлення виконане !'
              }

            }
            this.$emit('changeOrder',{id:this.order.id,data:this.order.data})
            this.notify(notifyText)



        }

        this.loading = false
      })
          .catch(()=>{this.loading = false})
    },
    // technical
    getQuantity(order) {
      let quantity = 0;
      order.products.forEach((item)=>{
        quantity += item.quantity
      })
      return quantity
    },
    getTotal(order) {
      let price = 0;
      order.products.forEach((item)=>{
        price += item.quantity * item.price
      })
      return price
    },
    getPayedText(payed) {  return payed ? 'Оплачено' : 'Не оплачено'  },
    getDeliveryType(type){

      switch(type) {
        case 'address'    : return 'Доставка';
        case 'self'       : return 'Самовивіз';
        case 'novaPoshta' : return 'Нова Пошта';
        default : return 'не обрано';
      }

    },
    getDeliveryStatusText(status) {
      switch (status) {
        case 'sent'      :  return 'Відправлено'
        case 'new'       :  return 'Готується';
        case 'recieved'  :  return 'Доставлено';
        default          :  return 'Готується';
      }
    },
    getStatusText(status) {
      if ( status === null) { return 'Всі' }
      switch ( status ) {
        case -1 : return 'Заявка';
        case  0 : return 'Активне';
        case  1 : return 'Виконано';
        case  2 : return 'Cкасоване';
      }

    },
    paymentName(id){
      let payment = ''

      switch (id) {
        case 'onRecieve_cash'        : payment =   'Готівка'          ;  break;
        case 'onRecieve_card'        : payment =   'Термінал'         ;  break;
        case 'online_card_transfer'  : payment =   'Оплата на карту'  ;  break;
        case 'online_liqpay'         : payment =   'LiqPay'           ;  break;
        case 'online_fondy'          : payment =   'Fondy'            ;  break;
      }

      return payment
    },
  }
}
</script>

<style scoped>
.smallText {
  font-size : 0.875rem
}
</style>