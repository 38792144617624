<template>
  <dash-page-new
      :title="$t('Orders')"
      :subtitle="$t('Orders')"
      icon="mdi-receipt-text-check-outline"
      :root="$t('Dashboard')"
      :root-location="businessDashLink()"
  >
    <template  #header_action>
      <v-btn
          :to="businessDashLink('orders/new')"
          class="noCaps" dark :color="wsATTENTION">
        <v-icon>mdi-plus</v-icon>
        {{ $t('CreateOrder') }}
      </v-btn>
    </template>
    <div :class="!SM ? '' : 'pa-3' ">

      <!-- Header statistics-->
      <v-row v-if="!SM"  class="mt-3">
        <v-col v-for="(block,i) in headerBlocks" :key="i"
               cols="12" sm="12" xs="12" md="12" lg="6" xl="6" class="" >
          <v-sheet height="100" dark :color="block.color" class="pb-4 pt-2 px-4 wsRoundedLight">
            <h5 class="mb-0  mb-2">{{  block.title  }}</h5>

            <div class="d-flex align-center">
              <v-icon size="40" class="mr-3">{{ block.icon }}</v-icon>
              <div>
                <div v-for="(line,k) in block.content" :key="k"  class="d-flex align-center">
                  <h5 style="width: 90px" class="mt-0 ">{{ line.text }}: </h5>
                  <h5  class="mt-0 ">{{ line.value }}  </h5>
                </div>

              </div>
            </div>


          </v-sheet>
        </v-col>
      </v-row>
      <!-- Filters -->

      <v-sheet v-if="!SM" :style="`border : 1px solid ${wsBACKGROUND}`"  class="wsRoundedLight mt-6 pa-3 px-9 ">
        <div class="d-flex justify-space-between flex-wrap align-center">
          <div class="d-flex align-center pr-16 mr-6">
            <v-icon class="mr-1" small >mdi-tune-vertical</v-icon>
            <v-sheet  class="mr-3 "  color="transparent" width="200">
              <v-chip  class=" font-weight-medium" small outlined>
                <v-icon small>mdi-magnify</v-icon>
                <v-text-field v-model="searchProducts" style="font-size: 12px " :placeholder="$t('Search')" height="10" flat solo hide-details background-color="transparent" />
              </v-chip>
            </v-sheet>

            <ftSelect v-model="filters.status" :items="filterTypes" >
              <v-btn
                  :block="displayShow('sm')"
                  :class="displayShow('sm') ? 'mt-2' : ''"
                  :dark="filters.status !== ''"
                  :outlined="filters.status === ''"
                  :color="filters.status !== '' ? wsDARKLIGHT : 'grey darken-3' "
                  small rounded class="noCaps mr-2" >
                <v-icon small>mdi-list-status</v-icon>
                {{ getStatusText(filters.status) }}
              </v-btn>
            </ftSelect>



            <v-btn icon small @click="resetFilter"><v-icon>mdi-close</v-icon> </v-btn>
          </div>
          <wsChip icon="mdi-account-school" >
            <h5 class="text-no-wrap">{{ $t('Total')}} : {{ orders_filtered.length }}</h5>
          </wsChip>
        </div>


      </v-sheet>

      <!-- mobile filters-->
      <div v-if="SM" class="d-flex px-4 justify-space-between align-center">
        <h4> Всього: {{ orders_filtered.length }}</h4>
        <ftSelect v-model="filters.status" :items="filterTypes" >
          <v-btn
              :block="displayShow('sm')"
              :class="displayShow('sm') ? 'mt-2' : ''"
              :dark="filters.status !== ''"
              :outlined="filters.status === ''"
              :color="filters.status !== '' ? 'blue-grey ' : 'grey darken-3' "
              small rounded class="noCaps mr-2" >
            <v-icon small>mdi-list-status</v-icon>
            <h3>{{ getStatusText(filters.status) }}</h3>
          </v-btn>
        </ftSelect>
      </div>

      <!-- :: MAIN CONTENT :: -->
      <v-sheet v-if="!SM" class="wsRoundedHalf  mt-6">
        <v-data-table
            :items="orders_filtered"
            :headers="orders_headers"
            class="mt-0 pt-0 wsHoverLight"
            selectable-key="id"
            :disable-sort="displayShow('sm')"
            v-model="selectedProducts"
            :search="searchProducts"
            hide-default-header
            :item-class="itemRowBackground" >

          <template v-slot:header="{ props }">
            <wsTableHeader :props="props" />
          </template>

          <template v-slot:body="{items}">
            <tbody>
            <tr v-for="(item,i) in items"
                class="pointer"
                @click="openDetails(item)"
                :key="i">

              <!-- Date-->
              <td  class=" text-no-wrap" width="10px">
                <h5 :style="`color : ${wsACCENT}`">{{ PARSE_TIME_STAMP(item.date,true) }} </h5>
                <h5 class="font-weight-light">{{ item.order_id }} </h5>
              </td>
              <!-- OrderNumber-->
              <!-- Name-->
              <td width="10px" class="text-no-wrap">
                <h5 :style="`color : ${wsACCENT}`">{{ item.name }} </h5>
                <h5 class="font-weight-light grey--text text--darken-3 ">{{ item.phone }}</h5>
              </td>
              <!-- Course-->
              <td>
                <div v-if="item.products.length === 1" >
                  <h5 class="">{{ item.products[0].name }} </h5>
                </div>
              </td>
              <!-- Price-->
              <td width="10px">
                <div class="d-flex">
                  <h5 class="text-no-wrap">{{ item.price }} {{ BUSINESS_CURRENCY }}</h5>
                </div>
              </td>
              <!--Payement Status-->
              <td align="center" class="px-0" width="10px" >
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip :color="item.payed ? wsSUCCESS : 'grey' "  dark small outlined  v-bind="attrs" v-on="on">
                      <h4> {{getPayedText(item.payed)}} </h4>
                    </v-chip>
                  </template>
                  <v-list dense dark>
                    <v-list-item-group >
                      <v-list-item   @click="openConfirmation('payment_status',item, true)">
                        <v-list-item-title>Оплачено</v-list-item-title>
                      </v-list-item>
                      <v-list-item   @click="openConfirmation('payment_status',item, 'false')">
                        <v-list-item-title>Не оплачено</v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </td>
              <!-- Action-->
              <td width="10px">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :color="wsATTENTION" icon  v-bind="attrs" v-on="on" >
                      <v-icon >mdi-pencil-circle</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item v-for="(menuItem, i) in editActions" :key="i" @click="editButton(menuItem.action,item)">
                      <v-list-item-title class="font-weight-light">{{ menuItem.label }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>

            </tr>
            </tbody>
          </template>

        </v-data-table>
      </v-sheet>

      <!-- mobile-->
      <v-data-iterator v-else
                       disable-sort

                       hide-default-footer
                       items-per-page="1000"
                       :items="orders_filtered"
                       :headers="orders_headers"
                       v-model="selectedProducts"
                       :search="searchProducts" >
        <template v-slot:default="{ items  }">
          <v-sheet v-for="(item,i) in items" :key="i"
                   @click="openDetails(item)"
                   class="pointer">

            <v-sheet dark :color="item.payed ? 'teal' : 'blue-grey' "
                     class="d-flex wsRoundedHalfTop align-center justify-space-between mt-5 px-4 py-1">
              <h6 class="font-weight-light">{{ PARSE_TIME_STAMP(item.date) }} </h6>
              <h5 class="font-weight-medium" >{{ item.order_id }} </h5>
              <div class="d-flex">
                <v-icon small class="mr-1"  >mdi-cash</v-icon>
                <h6 class="text-no-wrap font-weight-light">{{ item.price }} {{ BUSINESS_CURRENCY }}</h6>
              </div>
            </v-sheet>

            <v-sheet color="grey lighten-4" class="wsRoundedHalfBottom  py-2 px-3 " >

              <v-simple-table style="background-color: transparent" dense>
                <tbody>
                <tr style="background-color: transparent" >
                  <td width="100px">
                    <h5 class="font-weight-light">Адреса </h5>
                  </td>
                  <td>
                    <h5>
                              <span v-if="item.delivery ==='address' ">
                                {{ item.address }}
                              </span>
                      <span style="line-height: 1.2" class="caption" v-if="item.delivery ==='novaPoshta' ">
                                {{ item.city  }}<br>
                                {{ item.npWarehouse  }}
                              </span>
                    </h5>
                  </td>
                </tr>
                <tr style="background-color: transparent">
                  <td width="100px">
                    <h5 class="font-weight-light"> Ім'я </h5>
                  </td>
                  <td>
                    <h5 class="font-weight-medium">{{ item.name }}</h5>
                  </td>
                </tr>
                <tr style="background-color: transparent">
                  <td width="100px">
                    <h5 class="font-weight-light">Телефон</h5>
                  </td>
                  <td>
                    <h5 class="font-weight-medium">{{ item.phone }}</h5>
                  </td>
                </tr>
                <tr style="background-color: transparent">
                  <td width="100px">
                    <h5 class="font-weight-light">Коментар</h5>
                  </td>
                  <td>
                    <h5 class="font-weight-medium">{{ item.comment }}</h5>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
              <!-- Name-->



            </v-sheet>

          </v-sheet>
        </template>
      </v-data-iterator>



      <orderStatistics :orders="orders" v-if="orderView === 2" />
      <orderDetails          v-if    =  "displayOrderDetails"
                             v-model =  "displayOrderDetails"
                             :order  =  "orderDetailsInput"
                             @changeOrder="changeOrder" />


      <wsDialog v-model="displayConfirm"
                :loading="$store.state.LOADING"
                @save="confirmation(confirmType)"
                :title="$t('ConfirmOrderEdit')"
                :saveText="$t('Confirm')">
        <h5 class="text-center py-6"> {{  getConfirmationText(confirmType)  }}</h5>
      </wsDialog>




    </div>


  </dash-page-new>
</template>

<script>

import orderDetails from "@/components/pages/businessDashboard/businessOrders/orderDetails";
import {mapActions, mapState} from "vuex";
import orderStatistics from "@/components/pages/businessDashboard/businessOrders/orderStatistics";
import ftSelect from "@/components/UI/ftSelect";
export default {
  name: "BusinessDashboardOrders",
  components : {
    orderDetails,
    orderStatistics,
    ftSelect
  },
  data (){
    return {
      loading : false,

      filters : {
        status : 0,
        payment : '',
        delivery : '',
        warehouse : '',
        manager : '' ,
        interval : '',
        intervalValue : '',
        date : [],
        hide : false,
      },

      displayConfirm : false,
      confirmType : {type:'',data : {}},

      displayOrderDetails : false,
      orderDetailsInput : {},

      orderView : 0,

      categories : [],
      orders : [],
      productsQuantity : [],
      warehouses : [],

      showEditCategory : false,
      selectedCategory : '',

      selectedProducts : [],
      searchProducts : '',

      editActions : [

        { label: 'Переглянути', action: 'edit'   },
        { label: 'Скасувати',   action: 'cancel' },

      ],

      editGroupActions : [

        { label: 'Видалити',   action: 'delete_group' },

      ],

      filterStatus : { text : 'Активні', value : 0 },
      filterTypes : [
        { text : 'Всі',       value : null },
        { text : 'Активні',   value : 0    },
        { text : 'Виконані',  value : 1    },
        { text : 'Cкасовані', value : 2    },
      ],

      displayEditor : false,
      editorType : 'category',
      editorInput : false,
      editorRemove : false,
    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
    ...mapState('notifications',['notifications']),

    //distinct filter values
    distinct_payment() {
      let items = [{ text : 'Всі' , value : '' }]
      if (this.orders.length > 0) {
        let payments = this.orders.map(item => item.payment)
            .filter((value, index, self) => self.indexOf(value) === index)
        payments.forEach((payment)=>{
          items.push({ text : this.paymentName(payment), value : payment })
        })
      }

      return items
    },
    distinct_deliveries() {
      let items = [{ text : 'Всі' , value : '' }]
      if (this.orders.length > 0) {
        let deliveries = this.orders.map(item => item.delivery)
            .filter((value, index, self) => self.indexOf(value) === index)

        deliveries.forEach((delivery)=>{
          items.push({ text : this.getDeliveryType(delivery), value : delivery })
        })
      }

      return items
    },
    distinct_warehouses() {
      let items = [{ text : 'Всі' , value : '' }]
      if (this.orders.length > 0) {
        let warehouses = this.orders.map(item => item.warehouse)
            .filter((value, index, self) => self.indexOf(value) === index)
        warehouses = warehouses.filter(el=> el !== undefined)
        let warehouseNames = warehouses.map(item => item.name)
            .filter((value, index, self) => self.indexOf(value) === index)

        warehouseNames.forEach((warehouse)=>{
          items.push({ text : warehouse, value : warehouse })
        })
      }

      return items
    },
    distinct_managers() {
      let items = []
      if (this.orders_filtered.length > 0) {
        let payments = this.orders_filtered.map(item => item.payment)
            .filter((value, index, self) => self.indexOf(value) === index)
        payments.forEach((payment)=>{
          items.push({ text : this.paymentName(payment), value : payment })
        })
      }

      return items
    },
    distinct_dates() {
      let items = []
      if (this.orders_filtered.length > 0) {
        let payments = this.orders_filtered.map(item => item.payment)
            .filter((value, index, self) => self.indexOf(value) === index)
        payments.forEach((payment)=>{
          items.push({ text : this.paymentName(payment), value : payment })
        })
      }

      return items
    },

    // ORDERS
    orders_filtered() {
      var orders = this.orders
      // orders = orders.sort( (a,b) => {
      //
      //   if ( new Date(a.time).getTime() < new Date(b.time).getTime() ){
      //     return -1;
      //   }
      //   if ( new Date(a.time).getTime() > new Date(b.time).getTime() ){
      //     return 1;
      //   }
      //   return 0;
      // } )

      if ( this.filters.status !== null ) {
        orders = orders.filter(el=> el.status === this.filters.status)
      }
      if ( this.filters.warehouse ) {
        orders = orders.filter(el=> el.warehouse )
        orders = orders.filter(el=> el.warehouse.name === this.filters.warehouse )
      }
      if ( this.filters.payment ) {
        orders = orders.filter(el=> el.payment === this.filters.payment )
      }
      if ( this.filters.delivery ) {
        orders = orders.filter(el=> el.delivery === this.filters.delivery )
      }
      if ( this.filters.date.length > 1 ) {
        orders = orders.filter(el=> new Date(el.date) >= this.dateObjectStart && new Date(el.date) <= this.dateObjectEnd  )
      }
      if ( this.filters.search ) {
        orders = orders.filter((el)=> {
              return el.name.toLowerCase().includes(this.filters.search.toLowerCase()) ||
                  el.phone.toLowerCase().includes(this.filters.search.toLowerCase())
            }
        )
      }
      return orders
    },
    orders_gMap() {
      let orders = this.orders_filtered
      orders = orders.filter(el=> el.gPaths )
      return orders
    },
    orders_headers() {
      var headers = [


        {text:"Дата",value:'date', sortable : false},

        {text:"Клієнт",value:'name', sortable : false, align: 'left'},
        {text: this.$t('Order'),value:'order', align : 'left'},
        {text:'Ціна',value:'price', align : 'center'},
        {text:'Оплата',value:'payment', align : 'center'},
        {text:'',value:'action'},
        {value:'order_id', sortable : false , align: ' d-none'},
        {value:'email', sortable : false, align: ' d-none'},
        {value:'phone', sortable : false, align: ' d-none'},

      ]

      return headers
    } ,

    // date objects
    dateObjectStart() {
      let date = false
      if ( this.filters.date.length > 1 ) {
        date = new Date(this.filters.date[0])
        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)
      }
      return date
    },
    dateObjectEnd() {
      let date = false
      if ( this.filters.date.length > 1 ) {
        date = new Date(this.filters.date[1])
        date.setHours(23)
        date.setMinutes(59)
        date.setSeconds(59)
      }
      return date
    },
    dateObjectMonth() {
      let date = new Date();
      date.setDate(1)
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      return date
    },
    dateObjectDay() {
      let date = new Date();
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      return date
    },
    // statistics
    headerBlocks() {
      return [
        { title: 'Поточні Замовлення',
          content: [
            { text : 'Активні' , value : this.activeOrders },
            { text : 'Заявки' , value : this.applyOrders },
          ],
          icon: 'mdi-text-box-check-outline',
          color: this.wsDARKER},
        { title: 'Виконано за місяць',
          content: [
            { text : 'Замовлень' , value : this.monthOrders.length },
            { text : 'Доход' , value : this.monthOrdersTotal + ' ' + this.BUSINESS_CURRENCY },
          ],
          icon: 'mdi-calendar-month',
          color: this.wsACCENT }
      ]
    },

    activeOrders() {
      let orders = this.orders
      orders = orders.filter(el=> el.status === 0 )
      return orders.length
    },
    applyOrders() {
      let orders = this.orders
      orders = orders.filter(el=> el.status === -1 )
      return orders.length
    },
    monthOrders() {
      let orders = this.orders
      orders = orders.filter(el=>
          new Date(el.date) >= this.dateObjectMonth && el.status === 1
      )
      return orders
    },
    dayOrders() {
      let orders = this.orders
      orders = orders.filter(el=>
          new Date(el.date) >= this.dateObjectDay &&  el.status === 1 )
      return orders
    },
    periodOrders() {
      let orders = this.orders_filtered
      // orders = orders.filter(el=>
      //     new Date(el.date) >= this.dateObjectStart &&
      //     new Date(el.date) <= this.dateObjectEnd &&
      //         el.status === 1)
      return orders
    },
    monthOrdersTotal() {
      return this.getOrdersTotal(this.monthOrders)
    },
    dayOrdersTotal() {
      return this.getOrdersTotal(this.dayOrders)
    },
    periodOrdersTotal() {
      return this.getOrdersTotal(this.periodOrders)
    },
  },
  watch : {
    'EVENTS_TRIGGER.ORDER_COURSE'() {
      this.initPage()
    },
    'EVENTS_TRIGGER.ORDER_COURSE_PAYMENT'() {
      this.initPage()
    },

  },
  methods : {
    ...mapActions('orders', [ 'GET_BUSINESS_ORDERS','GET_LAST_ORDER','EDIT_BUSINESS_ORDER']),

    changeOrder(input){
      this.displayOrderDetails = input
    },
    getOrdersTotal(orders){
      let total = 0;
      orders.forEach((order)=>{
        total +=this.getTotalOrder(order)
      })
      return total
    },
    resetFilter() {
      this.filters = {
        status : 1,
        payment : '',
        delivery : '',
        warehouse : '',
        manager : '' ,
        interval : '',
        intervalValue : '',
        date : [],
        hide : false,
      }
    },
    editOrder(id,data) {
      this.loading = true
      this.EDIT_BUSINESS_ORDER({id:id,data:data}).then((out)=>{

        if(out.result) {


          let index = this.orders.findIndex(el=>el.id === id)
          let notifyText = 'Cтатус замовлення змінено'

          if (index !== -1) {

            if (data.payed) { data.payed = data.payed !== 'false' }
            if (data.status === 2 ) {  notifyText = 'Замовлення скасовано !' }
            if ( data.status === 'accept') { data.status = 0 }
            if ( data.warehouse ) {  data.warehouse = out.data.warehouse   }

            if(out.data) {
              if (out.data.completed) {
                data.status = 1
                notifyText = 'Замовлення виконане !'
              }

            }
            let order = Object.assign({},this.orders[index],data)
            this.$set(this.orders,index,order)
            this.notify(notifyText)

          }

        }

        this.loading = false
      })
          .catch(()=>{this.loading = false})
    },
    getNewOrder() {
      this.loading = true
      this.GET_LAST_ORDER(this.selectedBusiness.alias).then((out) => {
        out.data.new = true
        this.orders.unshift(out.data)
        this.loading = false
      }).catch( () => this.loading = false)
    },
    openDetails(order) {
      this.displayOrderDetails = true
      this.orderDetailsInput = order
    },
    getCategoryCount(category) {
      return this.products.filter(el=>el.category_id === category).length
    },
    editButton(action,item) {

      switch(action) {
        case 'edit'         : this.openDetails(item)                    ;   break;
        case 'cancel'       : this.openConfirmation('cancel',item) ;   break;
        case 'delete_group' : this.deleteProduct(this.selectedProducts) ;   break;
      }

    },
    confirmation(data) {
      this.displayConfirm = false
      switch(data.type) {
        case 'cancel'          : return this.cancelOrder(data.data) ;
        case 'delivery_status' : return this.editOrder(data.data.id,{ delivery_status : data.value});
        case 'payment_status'  : return this.editOrder(data.data.id,{ payed           : data.value})
      }

    },
    openConfirmation(type,order,value=null){
      this.displayConfirm = true
      switch(type) {

        case 'cancel'          : this.confirmType    =  { type : 'cancel',          data : order } ; break;
        case 'delivery_status' : this.confirmType    =  { type : 'delivery_status', data : order, value : value } ; break;
        case 'payment_status'  : this.confirmType    =  { type : 'payment_status',  data : order, value : value } ; break;
      }
    },
    getConfirmationText(order){
      let text = ''
      switch(order.type) {
        case 'cancel' :          text = `Ви впевнені що хочете скасувати замовлення ?`; break;
        case 'delivery_status' : text = `Підтвердіть зміну статуса доставки замовлення`; break;
        case 'payment_status'  : text = `Підтвердіть зміну статуса оплати замовлення`; break;
      }
      return text
    },
    cancelOrder(item){
      this.editOrder(item.id,{ status : 2 })
    },

    // technical
    getTotalOrder(order) {
      let price = 0;
      order.products.forEach((item)=>{
        price += item.quantity * item.price
      })
      return price
    },
    getDeliveryType(type){

      switch(type) {
        case 'address'    : return 'Доставка';
        case 'self'       : return 'Самовивіз';
        case 'novaPoshta' : return 'Нова Пошта';
        default : return 'не обрано';
      }

    },
    paymentName(id){
      let payment = ''

      switch (id) {
        case 'onRecieve_cash'        : payment =   'Готівка'          ;  break;
        case 'onRecieve_card'        : payment =   'Термінал'         ;  break;
        case 'online_card_transfer'  : payment =   'Оплата на карту'  ;  break;
        case 'online_liqpay'         : payment =   'LiqPay'           ;  break;
        case 'online_fondy'          : payment =   'Fondy'            ;  break;
      }

      return payment
    },
    getQuantity(order) {
      let quantity = 0;
      order.products.forEach((item)=>{
        quantity += item.quantity
      })
      return quantity
    },
    getPayedText(payed) {
      if (payed) { return 'Оплачено'} else return 'Не оплачено'
    },
    getDeliveryStatusText(status) {
      switch (status) {
        case 'sent'      :  return 'Відправлено'
        case 'new'       :  return 'Готується';
        case 'recieved'  :  return 'Доставлено';
        default          :  return 'Готується';
      }
    },
    getStatusText(status) {
      if ( status === null) { return 'Всі' }
      switch ( status ) {
        case -1 : return 'Заявки';
        case  0 : return 'Активні';
        case  1 : return 'Виконані';
        case  2 : return 'Cкасовані';
      }

    },

    //WarehouseModule
    getWarehouseSelect(warehouses){
      let selectData = []

      warehouses.forEach((ware)=>{
        selectData.push(
            {text : ware.name, value : ware.id}
        )
      })

      return selectData
    },
    itemRowBackground(item) {
      return item.new ?  'background-color : grey lighten-4' : ''
    },
    parseTime(timeInput) {
      let time = new Date(timeInput)
      let dateToday = new Date();
      let result = ''
      let timeString = ( "0" + time.getHours() ).slice(-2) +  ":" +( "0" + time.getMinutes() ).slice(-2)
      let dateString = ( "0" + time.getDate() ).slice(-2) + "." + ( "0" + (time.getMonth() + 1 ) ).slice(-2);

      if ( dateToday.getDate() === time.getDate() && dateToday.getMonth() === time.getMonth() &&  dateToday.getFullYear() === time.getFullYear() ) {
        result = timeString;
      } else {
        result = dateString + " " +  timeString ;
      }

      return result
    },
    async initPage() {
      this.loading = true
      this.GET_BUSINESS_ORDERS(this.selectedBusiness.alias).then((out)=>{
        if (out.data) {

          this.orders = out.data.orders

          this.productsQuantity = out.data.products
          out.data.warehouses.forEach((item)=>{
            this.warehouses.push({text:item.name, value : item.id})
          })


          this.orders.reverse()
          this.loading = false


        }
      }).catch(()=>{
        this.loading = false
        this.notify('Network error')
      })
      await this.READ_EVENTS('order_course')
      await this.READ_EVENTS('order_course_payment')
    }

  },
  mounted(){
    this.initPage()
  }

}
</script>

<style scoped>
.anim {
  cursor : pointer;
  transition: all 0.3s ease;
}
.smallText {
  font-size : 0.875rem
}
</style>